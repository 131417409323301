import React from "react";
import ReactDOM from "react-dom";
// import Preloader from "./components/Preloader/Preloader";
// import Timer from "./components/Countdown/Timer";
// import Optin from "./components/Optin/Optin";

import "./styles.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        {/* <image className src="Original-01.png"/> */}
        <p>it's coming...</p>
        <h1 className="Immiment">
          Imminent
          <br />

        </h1>
        {/* <Timer />
        <Optin />
        <Preloader /> */}
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
